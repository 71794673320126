import { EditOutlined, MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Dropdown, List, Menu, Row } from "antd";
import { Link, useHistory } from "react-router-dom";

const CreatedCollectionList = ({ createdCollectionList }) => {
    const history = useHistory();

    const createNewClick = () => {
        history.push("/collections/create");
    };

    const IconButtonDropdown = ({ id }) => {
        const handleMenuClick = ({ key, domEvent }) => {
            domEvent.preventDefault();
            domEvent.stopPropagation();

            switch (key) {
                case "edit":
                    history.push(`/collections/${id}/edit/`);
                    break;

                default:
                    break;
            }
        };
        return (
            <Menu
                onClick={handleMenuClick}
                items={[
                    {
                        label: "Edit",
                        key: `edit`,
                        icon: <EditOutlined />
                    }
                ]}
            />
        );
    };

    return (
        <Row>
            <Col span={4}>
                <div className="crete-new-course-button-wrap">
                    <Button
                        type="primary"
                        className="crete-new-course-button"
                        onClick={() => createNewClick()}>
                        <PlusOutlined className="icon" />
                        <span className="label">CREATE COURSE</span>
                    </Button>
                </div>
            </Col>
            <Col span={20}>
                <List
                    grid={{
                        gutter: [16, 16],
                        xs: 1,
                        sm: 2,
                        md: 4,
                        lg: 3,
                        xl: 4,
                        xxl: 4
                    }}
                    pagination={{
                        pageSize: 4
                    }}
                    dataSource={createdCollectionList}
                    renderItem={(collection) => (
                        <List.Item key={collection.id}>
                            <Link
                                to={`/creator/created-collections/${collection.id}`}>
                                <Card
                                    hoverable
                                    className="collection-card"
                                    cover={
                                        <>
                                            <img
                                                alt="example"
                                                src={
                                                    collection.coverImage.image
                                                }
                                            />
                                            <div className="card-menu-button">
                                                <Dropdown
                                                    overlay={
                                                        <IconButtonDropdown
                                                            id={collection.id}
                                                        />
                                                    }
                                                    trigger={["click"]}>
                                                    <Button
                                                        icon={<MoreOutlined />}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                        }}
                                                    />
                                                </Dropdown>
                                            </div>
                                        </>
                                    }>
                                    <Card.Meta title={collection.name} />
                                </Card>
                            </Link>
                        </List.Item>
                    )}
                />
            </Col>
        </Row>
    );
};

export default CreatedCollectionList;
