import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Tabs } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import YouTubeMarketingPlayer from "../Components/YouTubeMarketingPlayer";

import "./../Components.less";
import "./../styles/laas.scss";

class LaasPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeKey: "1"
        };
    }

    componentDidMount() {}

    render() {
        const tabLabel = (label, key) => (
            <div className="custom-tab-label">
                <span className="tab-text">{label}</span>
                <span
                    className={`tab-arrow ${
                        this.state.activeKey === key ? "visible" : ""
                    }`}>
                    <ArrowRightOutlined />
                </span>
            </div>
        );

        return (
            <section>
                <div className="laas-page-landing">
                    <h1 className="header-txt">Learning as a Service (LAAS)</h1>
                    <p className="header-sub-txt">
                        LaaS is a free service designed to help lecturers and
                        authors turn their videos into an interactive
                        AI-assisted course. Turn your videos or lecture slides
                        into a fully curated AI-assisted course—instantly and
                        for free. <br /> Try it today!
                    </p>
                    <Tabs
                        className="laas-tabs"
                        tabPosition={"left"}
                        activeKey={this.state.activeKey}
                        onChange={(key) => this.setState({ activeKey: key })}>
                        <Tabs.TabPane
                            tab={tabLabel("What is LAAS?", "1")}
                            key="1">
                            {this.state.activeKey === "1" && (
                                <div className="tab-content fade-in">
                                    <div className="video-container">
                                        <YouTubeMarketingPlayer
                                            youtubeId={"_hMjDtz9qBg"}
                                            pausable={true}
                                            options={{
                                                mute: 0,
                                                autoplay: 0
                                            }}
                                        />
                                    </div>
                                    {/* <p>
                                        Lorem ipsum dolor sit amet consectetur
                                        adipisicing elit. Illum consectetur
                                        praesentium velit quia magnam eum enim
                                        deleniti eaque assumenda!
                                    </p> */}
                                    <Button
                                        href="#overview-section"
                                        className="view-more-btn"
                                        size={"large"}>
                                        More Details
                                    </Button>
                                </div>
                            )}
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={tabLabel("How It Works", "2")}
                            key="2">
                            {this.state.activeKey === "2" && (
                                <div className="tab-content fade-in">
                                    <div className="video-container">
                                        <YouTubeMarketingPlayer
                                            youtubeId={"qx1pgRzls5g"}
                                            pausable={true}
                                            options={{
                                                mute: 0,
                                                autoplay: 0
                                            }}
                                        />
                                    </div>
                                    {/* <p>
                                        Lorem ipsum dolor sit amet consectetur
                                        adipisicing elit. Illum consectetur
                                        praesentium velit quia magnam eum enim
                                        deleniti eaque assumenda!
                                    </p> */}
                                    <Button
                                        href="#how-it-works-section"
                                        className="view-more-btn"
                                        size={"large"}>
                                        More Details
                                    </Button>
                                </div>
                            )}
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab={tabLabel("Already have Videos?", "3")}
                            key="3">
                            {this.state.activeKey === "3" && (
                                <div className="tab-content fade-in">
                                    <div className="video-container">
                                        <YouTubeMarketingPlayer
                                            youtubeId={"i6KU1K_b9ag"}
                                            pausable={true}
                                            options={{
                                                mute: 0,
                                                autoplay: 0
                                            }}
                                        />
                                    </div>
                                    {/* <p>
                                        Lorem ipsum dolor sit amet consectetur
                                        adipisicing elit. Illum consectetur
                                        praesentium velit quia magnam eum enim
                                        deleniti eaque assumenda!
                                    </p> */}
                                    <Button
                                        href="#mission-section"
                                        className="view-more-btn"
                                        size={"large"}>
                                        More Details
                                    </Button>
                                </div>
                            )}
                        </Tabs.TabPane>
                    </Tabs>
                </div>

                <div id="overview-section" className="laas-page-titles contain">
                    <h2>Overview</h2>
                    <section>
                        <p>
                            CUbits Learning-as-a-Service (LaaS) model provides{" "}
                            <b>free, comprehensive support</b> to instructors
                            and course creators, helping them develop engaging
                            video-based courses. It assists at every stage, from
                            course structuring and content curation to
                            integration of AI-driven tools like{" "}
                            <b>
                                auto-graded quizzes, chatbot (cuGPT), and smart
                                content delivery
                            </b>
                            . The platform is already used in multiple colleges
                            and by thousands of students worldwide.
                        </p>
                        <h2>The Benefits to Instructors</h2>
                        <p>
                            <ul>
                                <li>
                                    <b>Free conversions of Lecture Slides</b>{" "}
                                    into interactive videos.
                                </li>
                                <li>
                                    <b>Reduced Office Hours</b> as students can
                                    find help in videos.
                                </li>
                                <li>
                                    <b>Enhanced Student Engagement</b>, allowing
                                    deeper in class discussions.
                                </li>
                                <li>
                                    <b>24/7 Access to lecture Content</b> for
                                    students.
                                </li>
                                <li>
                                    <b>High Utilization Before Exams</b>, with
                                    80%+ of students relying on curated videos.{" "}
                                </li>
                                <li>
                                    <b>
                                        Better Control Over Learning Materials
                                    </b>
                                    , reducing reliance on unverified YouTube
                                    videos.
                                </li>
                            </ul>
                        </p>

                        <h2>Optional Features</h2>
                        <p>
                            <ul>
                                <li>
                                    Track student engagement and provide bonus
                                    points.
                                </li>
                                <li>
                                    Enable <b>cuGPT AI Assistant</b> to answer
                                    student queries.
                                </li>
                                <li>
                                    Share or market courses on CUbits and{" "}
                                    <b>monetize</b> content.
                                </li>
                            </ul>
                        </p>
                    </section>
                </div>

                <div
                    id="how-it-works-section"
                    className="laas-page-titles contain">
                    <h2>How It Works</h2>
                    <section>
                        <ol>
                            <li>
                                <b>Submit Lecture Notes</b> - Upload slides
                                (10-15 per lecture).
                            </li>
                            <li>
                                <b>Cubits Converts Slides</b> - Intro
                                interactive videos & structured courses.
                            </li>
                            <li>
                                <b>Full Editing Control</b> - Instructors can
                                modify content, add quizzes, and track
                                engagement.
                            </li>
                            <li>
                                <b>AI-Assisted Questions</b> - Auto-generated,
                                with the ability to customize.
                            </li>
                            <li>
                                <b>Optional SmartSlide Tool</b> - For
                                instructors who preferred to record using their
                                voice.
                            </li>
                        </ol>
                        <h2>Cost & monetization</h2>
                        <p>
                            <ul>
                                <li>
                                    <b>Free Service</b> - CUbits handles video
                                    creation at no cost.
                                </li>
                                <li>
                                    <b>Schedule Free Access</b> - Students
                                    access content for free during class
                                    coverage.
                                </li>
                                <li>
                                    <b>Micro payment Model</b> - After the free
                                    period, students can continue access for as
                                    low as $2.
                                </li>
                                <li>
                                    <b>Instructor Royalties</b> - Earn 30% - 50%
                                    based on contribution level.
                                </li>
                            </ul>
                        </p>
                    </section>
                </div>

                <div id="mission-section" className="laas-page-titles contain">
                    <h2>Mission</h2>
                    <section>
                        <p>
                            CUbits is <b>not a publisher or and institution</b>{" "}
                            - it is a{" "}
                            <b>
                                marketplace created by educators for educators
                            </b>
                            , ensuring <b>instructors retain ownership</b> and{" "}
                            <b>directly benefit from their content</b>. To get
                            started, visit{" "}
                            <a href="https://cubits.ai/laas">cubits.ai/Laas</a>{" "}
                            and upload your lecture slides.
                        </p>
                    </section>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user
    };
};

export default connect(mapStateToProps, {})(LaasPage);
