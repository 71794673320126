import { Button } from "antd";
import { useEffect, useState } from "react";
import { MdOutlineFilterList } from "react-icons/md";
import { useDispatch } from "react-redux";
import { setCategoryFilteredCollectionIds } from "../../actions/collection";
import { useHistory, useLocation } from "react-router-dom";

const CategoryFilter = ({ categories, collections, categoryOnly = false }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategory, setSelectedSubCategory] = useState(null);
    const [categoryList, setCategoryList] = useState([]);
    const [subCategories, setSubCategories] = useState([]);

    const filterChanged = (categoryId, subCategoryId) => {
        if (!categoryId && !subCategoryId) {
            dispatch(
                setCategoryFilteredCollectionIds(collections.map((c) => c.id))
            );
            return;
        }

        let filteredCollections = collections.filter((c) =>
            subCategoryId
                ? c.category === categoryId && c.subCategory === subCategoryId
                : c.category === categoryId
        );
        dispatch(
            setCategoryFilteredCollectionIds(
                filteredCollections.map((c) => c.id)
            )
        );
        if (categoryOnly) {
            history.push(`/collections/?category=${categoryId}`);
        }
    };

    const setCategoryFilter = (category, subCategory = null) => {
        setSelectedCategory(category);
        setSelectedSubCategory(subCategory);
        setSubCategories(
            category.id === null
                ? []
                : [{ id: null, name: "Any" }, ...category.subCategories]
        );
        filterChanged(category.id, subCategory ? subCategory.id : null);
    };

    const getUrlFilteredCategoriesAndSubCategories = () => {
        const queryParams = new URLSearchParams(location.search);
        let urlSelectedCategory = null;
        let urlSelectedSubCategory = null;
        if (!queryParams.has("category"))
            return { urlSelectedCategory, urlSelectedSubCategory };

        // Get URL filtered category
        urlSelectedCategory = categories.find(
            (c) => c.id === parseInt(queryParams.get("category"), 10)
        );
        queryParams.delete("category");

        // Get URL filtered sub category
        if (urlSelectedCategory && queryParams.has("subCategory")) {
            urlSelectedSubCategory = urlSelectedCategory.subCategories.find(
                (c) => c.id === parseInt(queryParams.get("subCategory"), 10)
            );
            queryParams.delete("subCategory");
        }

        // replace URL params
        history.replace({
            search: queryParams.toString()
        });
        return { urlSelectedCategory, urlSelectedSubCategory };
    };

    useEffect(() => {
        if (!collections || collections?.length === 0) return;

        let { urlSelectedCategory, urlSelectedSubCategory } =
            getUrlFilteredCategoriesAndSubCategories();

        if (urlSelectedCategory) {
            setCategoryFilter(urlSelectedCategory, urlSelectedSubCategory);
        } else {
            filterChanged(null, null);
        }
        setCategoryList(
            categoryOnly
                ? categories
                : [{ id: null, name: "All", subCategories: [] }, ...categories]
        );
    }, [collections, categories]);

    return (
        <>
            <div className="category-list-container">
                {categoryList.map((category, i) => (
                    <Button
                        key={i}
                        className={`collection-category-btn ${
                            (!selectedCategory && category.id === null) ||
                            category.id === selectedCategory?.id
                                ? "active"
                                : ""
                        }`}
                        size="small"
                        onClick={() => setCategoryFilter(category)}>
                        <MdOutlineFilterList />
                        {category.name}
                    </Button>
                ))}
            </div>
            {!categoryOnly && (
                <div className="sub-category-list-container">
                    {subCategories.map((subCategory, i) => (
                        <Button
                            key={i}
                            className={`collection-sub-category-btn ${
                                (!selectedSubCategory &&
                                    subCategory.id === null) ||
                                subCategory.id === selectedSubCategory?.id
                                    ? "active"
                                    : ""
                            }`}
                            size="small"
                            onClick={() =>
                                setCategoryFilter(selectedCategory, subCategory)
                            }>
                            {subCategory.name}
                        </Button>
                    ))}
                </div>
            )}
        </>
    );
};

export default CategoryFilter;
