import { Button, Result, Space, Spin, Typography } from "antd";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { CloseCircleOutlined } from "@ant-design/icons";

const { Title, Paragraph } = Typography;

const CollectionCreationResultStep = ({
    isEditMode,
    createCollectionStatus,
    updateCollectionStatus,
    updateCollectionErrors,
    goToPreviousStep,
    latestCreatedCollectionId
}) => {
    const history = useHistory();

    if (
        (isEditMode && updateCollectionStatus === "NOT ATTEMPTED") ||
        (!isEditMode && createCollectionStatus === "NOT ATTEMPTED")
    ) {
        return (
            <div style={{ textAlign: "center" }}>
                <Title level={3}>
                    {isEditMode
                        ? "Waiting to publish the changes to your collection..."
                        : "Waiting to publish your collection..."}
                </Title>
            </div>
        );
    } else if (
        (isEditMode && updateCollectionStatus === "LOADING") ||
        (!isEditMode && createCollectionStatus === "LOADING")
    ) {
        return (
            <div style={{ textAlign: "center" }}>
                <Space>
                    <Spin size="large" />
                    <Title level={3}>
                        {isEditMode
                            ? "Publishing the changes to your collection..."
                            : "Publishing your collection..."}
                    </Title>
                </Space>
            </div>
        );
    } else if (
        (isEditMode && updateCollectionStatus === "SUCCESS") ||
        (!isEditMode && createCollectionStatus === "SUCCESS")
    ) {
        return (
            <div style={{ textAlign: "center" }}>
                <Result
                    status="success"
                    title={
                        isEditMode
                            ? "Your collection is now updated!"
                            : "Your collection is now available!"
                    }
                    subTitle={
                        isEditMode
                            ? "Click the button below to view your updated collection."
                            : "Click the button below to find links to share your collection."
                    }
                    extra={[
                        <Button
                            type="primary"
                            onClick={() =>
                                history.push(
                                    `/creator/created-collections/${latestCreatedCollectionId}`
                                )
                            }>
                            View your collection
                        </Button>
                    ]}
                />
            </div>
        );
    } else if (
        (isEditMode && updateCollectionStatus === "FAILED") ||
        (!isEditMode && createCollectionStatus === "FAILED")
    ) {
        return (
            <div style={{ textAlign: "center" }}>
                <Result
                    status="error"
                    title="Submission Failed"
                    subTitle="Something went wrong with creating your collection."
                    extra={[
                        <>
                            {updateCollectionErrors.length > 0 && (
                                <div className="desc">
                                    {updateCollectionErrors.map((e, i) => (
                                        <div key={i}>
                                            <Paragraph style={{ color: "red" }}>
                                                <CloseCircleOutlined className="site-result-demo-error-icon" />{" "}
                                                {e}
                                            </Paragraph>
                                        </div>
                                    ))}
                                </div>
                            )}
                            <div>
                                <Button
                                    onClick={() => {
                                        goToPreviousStep();
                                    }}>
                                    Go Back
                                </Button>
                            </div>
                        </>
                    ]}
                />
            </div>
        );
    }
    return;
};
const mapStateToProps = (state) => {
    return {
        createCollectionStatus:
            state.contentCreationReducer.createCollectionStatus,
        latestCreatedCollectionId:
            state.contentCreationReducer.latestCreatedCollectionId,
        updateCollectionStatus:
            state.contentCreationReducer.updateCollectionStatus,
        updateCollectionErrors:
            state.contentCreationReducer.updateCollectionErrors
    };
};
export default connect(mapStateToProps, {})(CollectionCreationResultStep);
