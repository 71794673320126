import { useState } from "react";
import { Link } from "react-router-dom";
import { Typography, Image, Popover } from "antd";

import {
    FolderOutlined,
    LoadingOutlined,
    PlaySquareOutlined,
    VideoCameraOutlined
} from "@ant-design/icons";

import Cupoints from "./Cupoints";
import CollectionThumbnailPreviewer from "./CollectionThumbnailPreviewer";
import { secondsToHms } from "../utils";
import { getCollectionNetPrice } from "../helper";

const { Text } = Typography;

/** Certificate Hover Card */
const CertificateHoverContent = ({ collection }) => (
    <div className="certificate-hover-card-details">
        <h3 className="title">{collection.name}</h3>
        <div className="collection-details-bullets">
            {collection?.moduleCount > 0 && (
                <span>
                    <FolderOutlined />
                    {collection.moduleCount} Modules
                </span>
            )}
            {collection?.videoCount > 0 && (
                <span>
                    <VideoCameraOutlined />
                    {collection.videoCount} Videos
                </span>
            )}
            {collection?.totalDuration > 0 && (
                <span>
                    <PlaySquareOutlined />
                    {secondsToHms(collection.totalDuration || 0, false)}
                    total duration
                </span>
            )}
        </div>
        <p className="description">{collection?.certification?.description}</p>
        {collection.certification?.objectives?.length > 0 && (
            <>
                <h4>What you will learn</h4>
                <ul className="objectives">
                    {collection.certification?.objectives.map((obj, i) => (
                        <li key={i}>{obj}</li>
                    ))}
                </ul>
            </>
        )}
        <div className="certificate-collection-card-view-btn-section">
            <Link
                to={
                    collection.certification
                        ? `/collections/${collection.id}/certification/`
                        : `/collections/${collection.id}`
                }
                className="certificate-collection-card-view-btn">
                Go to Certificate Course
            </Link>
        </div>
    </div>
);

// Certificate Collection Card
const CertificateCollectionCard = ({
    collection,
    imagePreviewHeight = "200px",
    className = ""
}) => {
    const [popupVisible, setPopupVisible] = useState(false);
    const onPopupToggle = (event) => {
        setPopupVisible(event);
    };

    const getPurchaseAmount = () => {
        if (!collection) return 0;
        const collectionNetPrice = getCollectionNetPrice(collection, {}, false);
        if (!collection.promotion)
            return collectionNetPrice + collection.certification.price;

        const promotionDiscount = parseFloat(
            collection.promotion.promoDiscount
        );
        return (
            collectionNetPrice +
            Math.ceil(collection.certification.price * (1 - promotionDiscount))
        );
    };

    return (
        <Popover
            overlayClassName="content-section-popover"
            trigger={"hover"}
            arrowPointAtCenter={true}
            placement="right"
            onVisibleChange={onPopupToggle}
            overlayInnerStyle={
                {
                    // border: "thin solid gray"
                }
            }
            overlayStyle={{
                width: "25vw"
            }}
            content={() => <CertificateHoverContent collection={collection} />}>
            <div className={`certificate-collection-card border  ${className}`}>
                <div
                    className={`certificate-collection-card-image-bio`}
                    style={{ height: imagePreviewHeight }}>
                    <Link
                        to={
                            collection.certification
                                ? `/collections/${collection.id}/certification/`
                                : `/collections/${collection.id}`
                        }>
                        <CollectionThumbnailPreviewer
                            collection={collection}
                            videoType={
                                collection?.certification?.promotionVideoType
                            }
                            videoUrl={
                                collection?.certification?.promotionVideoUrl
                            }
                            thumbnail={
                                <Image
                                    src={
                                        collection?.certification?.image ||
                                        collection.coverImage.image
                                    }
                                    preview={false}
                                    width={"100%"}
                                    style={{ minHeight: imagePreviewHeight }}
                                    className="certificate-collection-card-image"
                                />
                            }
                        />
                    </Link>
                </div>
                <div className="certificate-collection-card-text">
                    <h3 className="title">{collection.name}</h3>
                    <p>
                        <Text type="secondary">
                            By{" "}
                            <Link
                                to={`/creators/${collection.primaryAuthor.id}`}>{`${collection.primaryAuthor.firstName} ${collection.primaryAuthor.lastName}`}</Link>
                        </Text>
                        <Text type="secondary">
                            {collection.coAuthors.map((coAuthor) => (
                                <Text key={coAuthor.id}>
                                    <Link
                                        to={`/creators/${coAuthor.id}`}>{`, ${coAuthor.firstName} ${coAuthor.lastName}`}</Link>
                                </Text>
                            ))}
                        </Text>
                    </p>
                    {collection?.certification && (
                        <>
                            <p className="price">
                                {collection.priceLoading ? (
                                    <LoadingOutlined />
                                ) : (
                                    <Cupoints points={getPurchaseAmount()}>
                                        Total Value:{" "}
                                    </Cupoints>
                                )}
                            </p>
                        </>
                    )}
                </div>
            </div>
        </Popover>
    );
};

export default CertificateCollectionCard;
