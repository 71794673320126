import YouTube from "react-youtube";
import "./../styles/yt-marketing-player.scss";

const playyerVars = {
    autoplay: 1,
    rel: 0,
    loop: 1,
    mute: 1,
    controls: 0,
    color: "white",
    modestbranding: 0,
    playsinline: 1,
    enablejsapi: 1,
    showinfo: 0,
    cc_load_policy: 3,
    iv_load_policy: 3
};

const YouTubeMarketingPlayer = ({
    youtubeId,
    pausable = false,
    options = {}
}) => {
    const PLAYER_OPTIONS = {
        width: "100%",
        height: "100%",
        playerVars: { ...playyerVars, ...options, playlist: youtubeId }
    };

    return (
        <div className={`yt-marketing-player ${pausable ? "pausable" : ""}`}>
            <YouTube
                videoId={PLAYER_OPTIONS.playerVars.playlist}
                opts={PLAYER_OPTIONS}
            />
        </div>
    );
};

export default YouTubeMarketingPlayer;
