import { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    Alert,
    Breadcrumb,
    Button,
    Card,
    Col,
    Divider,
    List,
    Modal,
    Result,
    Row,
    Space,
    Typography
} from "antd";
import { HomeOutlined, PlusOutlined } from "@ant-design/icons";
import {
    fetchUserCreatedCollections,
    fetchUserCreatedModules
} from "./actions/contentCreation";
import "./Components.less";
import CreatedCollectionList from "./Components/CreatedCollectionList";

const { Text, Title } = Typography;

class CreatorHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collection: {
                name: "Course Name",
                primaryAuthor: {
                    firstName: "AuthorFirstName",
                    lastName: "AuthorLastName"
                },
                coAuthors: [],
                modules: [
                    {
                        name: "1st Module Name",
                        primaryAuthor: {
                            firstName: "AuthorFirstName",
                            lastName: "AuthorLastName"
                        },
                        coAuthors: [],
                        videos: [
                            {
                                title: "Video Title",
                                embedId: "12345678",
                                videoType: "youtube"
                            },
                            {
                                title: "Video Title",
                                embedId: "12345678",
                                videoType: "youtube"
                            }
                        ]
                    },
                    {
                        name: "2nd Module Name",
                        primaryAuthor: {
                            firstName: "AuthorFirstName",
                            lastName: "AuthorLastName"
                        },
                        coAuthors: [],
                        videos: [
                            {
                                title: "Video Title",
                                embedId: "12345678",
                                videoType: "youtube"
                            },
                            {
                                title: "Video Title",
                                embedId: "12345678",
                                videoType: "youtube"
                            },
                            {
                                title: "Video Title",
                                embedId: "12345678",
                                videoType: "youtube"
                            },
                            {
                                title: "Video Title",
                                embedId: "12345678",
                                videoType: "youtube"
                            }
                        ]
                    }
                ]
            }
        };
    }
    componentDidMount() {
        if (this.props.user) {
            this.props.fetchUserCreatedModules(this.props.user.id);
            this.props.fetchUserCreatedCollections(this.props.user.id);
        }
    }

    render() {
        if (!this.props.user.isContentCreator) {
            return (
                <Result
                    status="403"
                    title="403"
                    subTitle="Sorry, you are not authorized to access this page."
                    extra={
                        <Button
                            type="primary"
                            onClick={() => this.props.history.push("/")}>
                            Go Back to Home
                        </Button>
                    }
                />
            );
        }

        return (
            <div className="centered-container">
                {/* <Modal visible={false} footer={null} width={1000}>
                    <div className="modal-body">
                        <Title level={4}>Welcome!</Title>
                        <CollectionInfoCard
                            collection={this.state.collection}
                            collectionNetPrice={200}
                            key={"tutorial-collection"}
                            mode="preview"
                        />
                        <CollectionModulesList
                            collection={this.state.collection}
                            mode="preview"
                            urlParams={this.props.match.params}
                        />
                    </div>
                </Modal> */}
                <Space className="breadcrumbs">
                    <Breadcrumb>
                        <Breadcrumb.Item>
                            <Link to="/">
                                <HomeOutlined />
                            </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Creator Home</Breadcrumb.Item>
                    </Breadcrumb>
                </Space>
                {/* <Card>
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={9} md={8} lg={8} xl={8}>
                            <Space direction="vertical">
                                Ready to create a full course?
                                <Button
                                    type="primary"
                                    onClick={() =>
                                        this.props.history.push(
                                            "/collections/create"
                                        )
                                    }>
                                    Create Course
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={24} sm={15} md={12} lg={8} xl={8}>
                            <Space direction="vertical">
                                Or do you want to assemble videos for a small
                                topic?
                                <Button
                                    onClick={() =>
                                        this.props.history.push(
                                            "/modules/create"
                                        )
                                    }>
                                    Create Module
                                </Button>
                            </Space>
                        </Col>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}></Col>
                    </Row>
                </Card> */}
                <br />
                {/* <Title level={5}>Tutorials</Title>
                <Space wrap>
                    <Button size="large">
                        <Link to="/walkthrough/collection/">
                            What is a course?
                        </Link>
                    </Button>
                    <Button size="large">
                        <Link to="/walkthrough/create-collection/">
                            How do I create a course?
                        </Link>
                    </Button>
                </Space> */}
                <Divider orientation="left" orientationMargin="0">
                    My Courses
                </Divider>

                {/* My course List */}
                <CreatedCollectionList
                    createdCollectionList={this.props.createdCollections}
                />

                <Divider orientation="left" orientationMargin="0">
                    <span>My Modules</span>
                    <Button
                        className="ml-20"
                        onClick={() =>
                            this.props.history.push("/modules/create")
                        }>
                        Create Your Module
                    </Button>
                </Divider>
                <List
                    pagination={{
                        pageSize: 10
                    }}
                    dataSource={this.props.createdModules}
                    renderItem={(module) => (
                        <List.Item
                            key={module.id}
                            className="created-modules-list-item">
                            <Link
                                to={`/creator/created-modules/${module.id}`}
                                style={{ width: "100%" }}>
                                <Card>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between"
                                        }}>
                                        <div>
                                            <Title
                                                level={5}
                                                style={{ margin: 0 }}>
                                                {module.name}
                                            </Title>

                                            <>
                                                <Text type="secondary">{`${module.primaryAuthor.firstName} ${module.primaryAuthor.lastName}`}</Text>
                                                <Text type="secondary">
                                                    {module.coAuthors.map(
                                                        (coAuthor) => (
                                                            <Text
                                                                key={
                                                                    coAuthor.id
                                                                }>{`, ${coAuthor.firstName} ${coAuthor.lastName}`}</Text>
                                                        )
                                                    )}
                                                </Text>
                                            </>
                                        </div>
                                        <div></div>
                                    </div>
                                </Card>
                            </Link>
                        </List.Item>
                    )}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        createdCollections: state.contentCreationReducer.userCreatedCollections,
        createdModules: state.contentCreationReducer.userCreatedModules,
        user: state.userReducer.user
    };
};

export default connect(mapStateToProps, {
    fetchUserCreatedCollections,
    fetchUserCreatedModules
})(CreatorHome);
