import { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import CertificateSample from "./assets/images/certificate-sample.png";

import "./Components.less";
import Slider from "@ant-design/react-slick";
import CertificateCollectionCard from "./Components/CertificateCollectionCard";
import CertificateCollectionCardLoading from "./Components/CertificateCollectionCardLoading";

/** Certification Slider */
const PromotedCollectionSlider = ({ collections }) => {
    const sliderSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1
    };

    return (
        <>
            <div className="" style={{ color: "black" }}>
                <div id="promoted-collection-mid-section">
                    <section id="promoted-collection">
                        <div>
                            <div id="collections-carousel">
                                {collections.length > 0 ? (
                                    <>
                                        <div className="slider-container">
                                            <Slider {...sliderSettings}>
                                                {collections.map(
                                                    (collection, index) => (
                                                        <div
                                                            className="certification-slider-item"
                                                            key={index}>
                                                            <CertificateCollectionCard
                                                                collection={
                                                                    collection
                                                                }
                                                            />
                                                        </div>
                                                    )
                                                )}
                                            </Slider>
                                        </div>
                                    </>
                                ) : (
                                    <div className="loading-section">
                                        {[...Array(4).keys()].map((i) => (
                                            <CertificateCollectionCardLoading
                                                key={i}
                                            />
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    );
};

class Certifications extends Component {
    constructor(props) {
        super(props);
    }
    componentDidMount() {}

    render() {
        return (
            <section className="collection-page-section">
                <h1 className="page-title">Be Ready for the AI revolution</h1>
                <h3 className="page-title-2">
                    The most in-demand certificate courses taught by Ivy League
                    and Top University Professors and Industry Experts that are
                    AI-boosted
                </h3>
                <h5 className="page-title-3">
                    Certify Critical Skills Necessary to Find Technical Jobs in
                    the AI Age
                </h5>
                <p className="text-center explore-btn-section">
                    <Link
                        className="explore-btn"
                        to="/certification-collections">
                        Explore All Certifications
                    </Link>
                </p>

                <PromotedCollectionSlider
                    collections={this.props.collections.filter(
                        (col) => col.certification !== null
                    )}
                />

                <div id="why-cubits-works" className="contain">
                    <h2>Why CUcertify?</h2>
                    <section>
                        <p className="why-cubits-works-para">
                            We integrate Machine Learning and Artificial
                            Intelligence into every stage of the learning and
                            evaluation process, ensuring that our certificates
                            are rigorously validated. You'll learn from top-tier
                            instructors affiliated with world-renowned
                            institutions. Our courses are meticulously curated
                            and packed with interactive exercises. cuGPT, our
                            custom genAI, is always available to assist you with
                            any questions.{" "}
                            <i>
                                CUbits certificates are crafted to validate
                                specific, in-demand skills that employers are
                                actively seeking.
                            </i>
                        </p>
                        <p className="why-cubits-works-para">
                            The CUbits comprehensive dashboard offers a detailed
                            overview of your progress, both during and after the
                            course, giving employers confidence in the skills
                            you've acquired and aiding them in making informed
                            hiring decisions. Share detailed dashboard with
                            potential employers
                        </p>
                        <p className="why-cubits-works-para">
                            Upon completing all the requirements, you will
                            receive a certificate like this:
                        </p>
                        <p>
                            <img
                                className="certificate-sample"
                                src={CertificateSample}
                            />
                        </p>
                    </section>
                </div>

                <div id="how-you-get-paid" className="contain">
                    <h2>How Certifications Works</h2>
                    <p className="why-cubits-works-para heading">
                        CUbits courses are structured into modules, with each
                        module consisting of a few short videos that can be
                        completed in 2-3 hours. You have the flexibility to
                        watch these interactive videos at your own pace—whether
                        you prefer to watch, listen, or search through the
                        content. For any questions, cuGPT is at your disposal,
                        ready to summarize content, provide practice questions,
                        or clarify concepts covered in the videos. This seamless
                        integration of AI is designed to enhance your learning
                        experience.
                    </p>
                    <p className="why-cubits-works-para heading">
                        As you progress through the videos, quiz questions will
                        appear at key moments, including multiple-choice,
                        select-all-that-apply, and open-ended questions. You
                        have unlimited attempts to answer, but to earn honors,
                        you must complete all questions with an average of 1.5
                        attempts or fewer per question. To pass the course, you
                        need an average of 2.0 attempts or fewer. If your
                        average is between 2.0 and 2.5 attempts per question,
                        you may request to retake the some modules once again.
                    </p>
                    <p className="why-cubits-works-para heading">
                        The cubits dashboard is comprehensive providing your
                        performance metrics in all aspects of the course
                    </p>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.userReducer.user,
        collections: state.collectionReducer.originalCollections
    };
};

export default connect(mapStateToProps)(Certifications);
