import { Button, Image, List, Space } from "antd";
import "./style.css";
import { useHistory } from "react-router-dom";
import { getCollectionNetPrice } from "../../../helper";
import { useEffect, useState } from "react";

const PromotionItem = ({ promotion, isAuthenticated, moduleAccess }) => {
    const history = useHistory();

    const [netPrice, setNetPrice] = useState(0);
    const [promotionDiscount, setPromotionDiscount] = useState(0);

    const bindPromoDiscountedPrices = () => {
        const collectionNetPrice = getCollectionNetPrice(
            promotion.collection,
            moduleAccess,
            isAuthenticated
        );
        setNetPrice(collectionNetPrice);

        const promotionDiscount = parseFloat(promotion.promoDiscount);
        setPromotionDiscount(
            Math.ceil(collectionNetPrice * (1 - promotionDiscount))
        );
    };

    const visitCollection = () => {
        history.push(
            promotion.redirectCertification &&
                promotion.collection.certification
                ? `/collections/${promotion.collection.id}/certification/?${
                      promotion.collection.collectionUnlistedKey
                          ? "key=" +
                            promotion.collection.collectionUnlistedKey +
                            "&"
                          : ""
                  }promotion_code=${promotion.code}`
                : `/collections/${promotion.collection.id}?${
                      promotion.collection.collectionUnlistedKey
                          ? "key=" +
                            promotion.collection.collectionUnlistedKey +
                            "&"
                          : ""
                  }promotion_code=${promotion.code}`
        );
    };

    useEffect(() => {
        bindPromoDiscountedPrices();
    }, []);

    return (
        <List.Item key={promotion.collection.name}>
            <div className="promo-collection-list-item">
                <div className="detail-section">
                    <h4 onClick={visitCollection}>
                        {promotion.collection.name}
                    </h4>

                    {promotion.description}

                    <h5 className="promotion-price">
                        <span className="strike-price small">{netPrice}</span>{" "}
                        {promotionDiscount} cupoints
                    </h5>

                    <Space className="ant-space-responsive">
                        <Button type="primary" onClick={visitCollection}>
                            View More
                        </Button>
                    </Space>
                </div>
                <div className="image-section">
                    <div className="promo-off-label">
                        {parseFloat(promotion.promoDiscount) * 100}
                        {"% "}
                        OFF
                    </div>
                    <Image
                        src={promotion.collection.coverImage.image}
                        preview={false}
                        width={200}
                    />
                </div>
            </div>
        </List.Item>
    );
};

const PromotionTab = ({ promotions, isAuthenticated, moduleAccess }) => {
    return (
        <div id="wrapper" className="collection-promo-wrapper">
            <List
                itemLayout="vertical"
                size="large"
                dataSource={promotions}
                renderItem={(promotion) => (
                    <PromotionItem
                        promotion={promotion}
                        isAuthenticated={isAuthenticated}
                        moduleAccess={moduleAccess}
                    />
                )}
            />
        </div>
    );
};

export default PromotionTab;
